import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ReportStyle.css";
import { v4 as uuidv4 } from "uuid";
import { RotateSpinner } from "react-spinners-kit";
import Header from "../Header/Header";
import Navbar from "../Header/Navbar";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  ROUTER_URL,
  LABEL_NAME,
  exportAsExcel,
  SERVICE_TYPE,
} from "../Common/constant";
import ActionButton from "../Common/ActionButton";
import Button from "@mui/material/Button";

const RENEWAL_COLUMNS = [
  { id: "Sl_No", label: "Sl No", minWidth: 50 },
  { id: "eiaNo", label: "eIA No", minWidth: 100 },
  { id: "customerName", label: "Customer Name", minWidth: 100 },
  { id: "insurerName", label: "Insurer Name", minWidth: 100 },
  { id: "policyNo", label: "Policy Number", minWidth: 100 },
  {
    id: "frequencyOfPremium",
    label: "Frequency of Premium payment",
    minWidth: 100,
  },
  { id: "modeOfReceipt", label: "Mode of renewal", minWidth: 100 },
  { id: "premiumAmount", label: "Premium Amount", minWidth: 100 },
  { id: "amount", label: "Paid Amount", minWidth: 100 },
  { id: "dueDate", label: "Due Date", minWidth: 100 },
  { id: "paymentId", label: "Payment Id", minWidth: 100 },
  { id: "orderId", label: "Order Id", minWidth: 100 },

  { id: "reqResErr", label: "Premium Paid date", minWidth: 100 },
  { id: "reqResErr", label: "Unique Trxn ID", minWidth: 100 },
  { id: "reqResErr", label: "Mode of Payment", minWidth: 100 },
  { id: "status", label: "Payment Status", minWidth: 100 },
];

const ENDOESMENT_COLUMNS = [
  { id: "Sl_No", label: "Sl No", minWidth: 50 },
  { id: "policyNumber", label: "Policy No", minWidth: 100 },
  { id: "serviceType", label: "Service Type", minWidth: 100 },
  { id: "reason", label: "Reason", minWidth: 100 },
  { id: "eIAHolderName", label: "eIA Holder Name", minWidth: 100 },
  { id: "ScannedUserCode", label: "Scanned User Code", minWidth: 100 },
  { id: "mobileNumber", label: "Mobile Number", minWidth: 100 },
  // { id: "workItemNumber", label: "Insurer Code", minWidth: 100 },
  { id: "policyNumber", label: "Policy Number", minWidth: 100 },
  {
    id: "sMSCommunicationTriggeringDate",
    label: "SMS Communication Triggering Date",
    minWidth: 100,
  },
  { id: "transactionName", label: "Transaction Name", minWidth: 100 },
  { id: "policyStatus", label: "Policy Status", minWidth: 100 },

  { id: "eIANumber", label: "eIA Number", minWidth: 100 },
  { id: "eIAStatus", label: "eIA Status", minWidth: 100 },
  {
    id: "eMailTriggeringDeliveryDate",
    label: "Email Triggering Delivery Date",
    minWidth: 100,
  },
  { id: "pgMode", label: "PG Mode", minWidth: 100 },
  { id: "directorOnLine", label: "Director Online", minWidth: 100 },
  { id: "eIAServiceProcesTAT", label: "eIA Service Proces TAT", minWidth: 100 },
  { id: "oldValue", label: "Old Value", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "responseToInsurer", label: "Response To Insurer", minWidth: 100 },
  { id: "newValue", label: "New Value", minWidth: 100 },
  {
    id: "eMailCommunicationDeliveryDate",
    label: "Email Communication Delivery Date",
    minWidth: 100,
  },
  { id: "ScannedLocation", label: "Scanned Location", minWidth: 100 },
  { id: "reqReferenceNumber", label: "Req Reference Number", minWidth: 100 },
  { id: "aPName", label: "aPName", minWidth: 100 },
  {
    id: "customerCommunicationTAT",
    label: "Customer CommunicationTAT",
    minWidth: 100,
  },
  { id: "panNumber", label: "PAN Number", minWidth: 100 },
  { id: "insurerCode", label: "Insurer Code", minWidth: 100 },
  { id: "insurerName", label: "Insurer Name", minWidth: 100 },
  {
    id: "policyCreditDate",
    label: "SMS Communication Delivery Date",
    minWidth: 100,
  },
  { id: "policyCreditDate", label: "Policy Credit Date", minWidth: 100 },
  { id: "transactionType", label: "Transaction Type", minWidth: 100 },
  { id: "createdDt", label: "Created Date", minWidth: 100 },

  { id: "uidNumber", label: "UID Number", minWidth: 100 },
  {
    id: "transactionStatus",
    label: "Status Success Failure",
    minWidth: 100,
  },
  { id: "msgCompletedDate", label: "Completion Date", minWidth: 100 },
  { id: "requestReceivedDate", label: "Request Received Date", minWidth: 100 },
  { id: "crreceiveDate", label: "CR Receive Date", minWidth: 100 },
  { id: "inactivationDate", label: "IN-Activation Date", minWidth: 100 },
  { id: "download", label: "Download", minWidth: 100 },
];

function Renewal({ isRenewal }) {
  const columns = isRenewal ? RENEWAL_COLUMNS : ENDOESMENT_COLUMNS;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const currentDate = new Date();

  // Format the date components individually
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = currentDate.getDate().toString().padStart(2, "0");
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");
  const milliseconds = currentDate
    .getMilliseconds()
    .toString()
    .padStart(3, "0");

  // Combine the components into the desired format
  const timeStamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}00`;

  const [showTable, setShowTable] = useState(false);
  const [responseData, setResponseData] = useState([]);

  const [transactionId, setTransactionId] = useState(uuidv4());

  const generateNewTransactionId = () => {
    const newTransactionId = uuidv4();
    setTransactionId(newTransactionId);
  };

  const [insurerCode, setInsurerCode] = useState("");
  const [EiaNo, setEiaNo] = useState("");
  const [policyType, setPolicyType] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [loading, setLoading] = useState(false);

  function formatDate(dateString) {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Months are zero-based
    const year = dateObject.getFullYear();

    // Pad single-digit day and month with a leading zero
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  const handleButtonClick = async (e) => {
    setLoading(true);
    setShowTable(false);
    generateNewTransactionId();

    console.log("button clicked");
    e.preventDefault();
    // const formattedFromDate = formatDate(fromDate);

    // const formattedToDate = formatDate(toDate);
    if (!policyType || !fromDate || !toDate) {
      alert("Please fill in all mandatory fields");
      setLoading(false);

      return;
    }
    const formattedFromDate = new Date(fromDate).toLocaleDateString("en-GB"); // Assumes 'toDate' is in the format "YYYY-MM-DD"

    const formattedToDate = new Date(toDate).toLocaleDateString("en-GB"); // Assumes 'toDate' is in the format "YYYY-MM-DD"

    //Prod

    const BaseUrl = process.env.REACT_APP_API_URL;

    const renewalEndPoint = "/riInsurerBulkService/v1/getRenewalReportDetails";

    const endoesmentEndPoint =
      "/riInsurerBulkService/v1/getEndoreshmentReportDetails";

    const EndPoint = isRenewal ? renewalEndPoint : endoesmentEndPoint;

    try {
      const response = await fetch(`${BaseUrl}${EndPoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          txnId: transactionId,
          timestamp: "2023-11-20T16:33:25.387994200",
          Source: {
            appType: "MOBILE",
            osVersion: "11",
            deviceId: "",
            deviceName: "Mymobile 52",
            deviceOS: "Android",
            appVersion: "2.0",
            clientIp: "0.0.0.0",
            origin: "",
          },
          Customer: {
            fromDate: formattedFromDate,
            toDate: formattedToDate,
            insuranceCode: "",
            policyType: policyType,
          },
        }),
      });
      // console.log(
      //   insurerCode,
      //   policyType,
      //   formattedFromDate,
      //   formattedToDate,
      //   transactionStatus
      // );
      if (response.ok) {
        setLoading(false);
        let jsonResponse = await response.json();
        let res = jsonResponse[policyType];

        //conversion start here
        let convertedData = [];
        res.forEach((element, index) => {
          let temp = {};
          debugger;
          // temp["Sl No"] = "0";
          columns.forEach((column) => {
            if (column.id === "Sl_No") {
              temp[column.label] = index + 1;
            } else if (
              column.id === "requestType" ||
              column.id === "serviceType"
            ) {
              temp[column.label] = SERVICE_TYPE[element[column.id]];
            } else {
              temp[column.label] = element[column.id];
            }
          });
          convertedData.push(temp);
          temp = {};
        });

        setResponseData(convertedData);
        setShowTable(true);
      } else {
        // Handle errors
        const errorResponse = await response.json();
        setLoading(false);
        setShowTable(false);
        alert(`Error: ${errorResponse.errorDescription}`);
      }
    } catch (error) {
      // Handle network errors or any other exceptions
      setLoading(false);
      setShowTable(false);
      console.error("Error:", error.message);
    }
  };

  const handleExportClick = () => {
    // Export response data as Excel
    exportAsExcel(
      `${isRenewal ? "Renewal" : "Endorsment"
      } report From ${fromDate} to ${toDate}.xlsx`,
      responseData
    );
    // console.log("Export Success");
  };

  const navigate = useNavigate();

  const backClick = () => {
    navigate("/menudisplaypage");
  };

  const resetClick = (e) => {
    e.preventDefault();
    setFromDate("");
    setToDate("");
    setShowTable(false);
    setLoading(false);
    setPolicyType("Select");
    setTransactionStatus("");
  };

  const todayDate = new Date().toISOString().split("T")[0];

  const handleDownload = async (row) => {

    const BaseUrl = process.env.REACT_APP_API_URL;

    const UrlViewInsaMatch = "/insurerDataCollection/v1/GetEndorsementPdf";

    try {
      generateNewTransactionId();
      const response = await fetch(`${BaseUrl}${UrlViewInsaMatch}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          txnId: transactionId,
          timestamp: "2023-07-31T19:43:25.387994200",
          Source: {
            appType: "MOBILE",
            osVersion: "11",
            deviceId: "",
            deviceName: "Mymobile 52",
            deviceOS: "Android",
            appVersion: "2.0",
            clientIp: "0.0.0.0",
            origin: ""
          },
          Customer: {
            endoId: row?.["Req Reference Number"],
            insuranceCmpnyCd: row?.["Insurer Code"]
          }
        }
        ),
      });

      if (response.ok) {
        const res = await response?.text();
        const linkSource = `data:application/pdf;base64,${res}`;
        const downloadLink = document.createElement("a");
        const fileName = `${row?.["Req Reference Number"]}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }

    }
    catch (error) {
      console.error("Error:", error.message);
    }

  }

  return (
    <div>
      <Header />
      <div className="MenuPage">
        <Navbar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
      </div>
      <div className="app-insurer">
        <div>
          <h1 className="heading">
            {isRenewal ? ROUTER_URL.RENEWAL : ROUTER_URL.ENDORSEMENT}
          </h1>
        </div>

        {/* Add your text fields and button for user input */}
        <div className="row col-12" style={{ "margin-top": "-15px" }}>
          <div className="col-12">
            <form method="post">
              <div class="row">
                <div className="username col-sm-3 col-xs-12">
                  <div>
                    <label className="label_cls">
                      {LABEL_NAME.POLICY_TYPE}
                    </label>
                  </div>
                  <div class="" style={{ width: "100%" }}>
                    <select
                      className="end-select"
                      value={policyType}
                      onChange={(e) => setPolicyType(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Health">Health</option>
                      <option value="Motor">Motor</option>
                      <option value="Life">Life</option>
                    </select>
                  </div>
                </div>
                <div className="username col-sm-2 col-xs-12">
                  <label className="label_cls">{LABEL_NAME.FROM_DATE}</label>
                  <div class="">
                    <input
                      type="date"
                      name="fromDate"
                      value={fromDate}
                      className="inputboxvu_insure form-control"
                      max={todayDate}
                      onChange={(e) => setFromDate(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="username col-sm-2 col-xs-12">
                  <label className="label_cls">{LABEL_NAME.TO_DATE}</label>
                  <div class="">
                    <input
                      type="date"
                      name="toDate"
                      value={toDate}
                      className="inputboxvu_insure form-control"
                      max={todayDate}
                      onChange={(e) => setToDate(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="username col-sm-3 col-xs-12">
                  <ActionButton
                    handleBack={backClick}
                    handleReset={resetClick}
                    handleSubmit={handleButtonClick}
                  />
                </div>
                <p className="note">
                  {" "}
                  Note:{" "}
                  {isRenewal
                    ? LABEL_NAME.RENEWAL_NOTE
                    : LABEL_NAME.ENDORSEMENT_NOTE}
                </p>
              </div>
            </form>
            <div className="loading">
              <RotateSpinner size={70} color="#f99117" loading={loading} />
            </div>
          </div>
          <div className="col-12">
            <div className="item-insurer">
              <div className="tableContainer-insurer">
                {showTable && (
                  <div className="">
                    <button
                      onClick={handleExportClick}
                      className="export-button"
                    >
                      Export as Excel
                    </button>
                    {/* <Button variant="outlined">Outlined</Button> */}
                    <div class="">
                      <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {responseData
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                  // console.log(row, index);
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={row.code}
                                    >
                                      {columns.map((column) => {
                                        const value = row[column.label];
                                        return (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            {/* {column.format && typeof value === 'number'
                                                                                     ? column.format(value)
                                                                                     : value} */}
                                            {value}
                                            {/* {column.id === "status" && value === "Success" && (row["eIA number"] !== "NA" && row["Policy number"] !== "NA") ? ( */}
                                            {!isRenewal && column.id === 'download' && <Button
                                              variant="contained"
                                              className="request-button ml-2"
                                              disabled={!row["Req Reference Number"]?.length}
                                              onClick={() =>
                                                handleDownload(
                                                  row
                                                )
                                              }
                                            >
                                              Download
                                            </Button>}
                                            {/* ) : (
                                              ""
                                            )} */}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 100]}
                          component="div"
                          count={responseData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Renewal;
